/* TaskManagement.css */

body {
  font-family: Arial, sans-serif;
  background-color: #000000;
  margin: 0;
  padding: 20px;
}

h3 {
  color: #333;
}

label {
  display: block;
  margin-top: 10px;
  font-weight: bold;
}

input, select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #218838;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #000000;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #000000;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #000000;
}

tr:hover {
  background-color: #9b4747;
}

