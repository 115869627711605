/* Styles/Contact.css */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
  }
  
  :root {
    --bg-color: #080808;
    --second-bg-color: #080808;
    --text-color: white;
    --main-color: #00ffee;
    --input-bg-color: #333;
    --input-text-color: #fff;
  }
  
  body {
    background: var(--bg-color);
    color: var(--text-color);
  }
  
  .heading {
    text-align: center;
    font-size: 3.9rem;
    margin-bottom: 2rem;
    color: var(--main-color);
  }
  
  form {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background: var(--second-bg-color);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .input-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
  
  .input-box input {
    width: 48%;
    padding: 1rem;
    font-size: 2rem;
    border-radius: 4px;
    background: var(--input-bg-color);
    color: var(--input-text-color);
    border: 1px solid var(--input-bg-color);
    transition: border-color 0.3s;
  }
  
  .input-box input:focus {
    border-color: var(--main-color);
  }
  
  .messageTextarea {
    font-size: 2rem;
    width: 100%;
    height: 150px;
    padding: 1rem;
    border-radius: 4px;
    background: var(--input-bg-color);
    color: var(--input-text-color);
    border: 1px solid var(--input-bg-color);
    margin-bottom: 1.5rem;
    transition: border-color 0.3s;
  }
  
  .messageTextarea:focus {
    border-color: var(--main-color);
  }
  
  
  