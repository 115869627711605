.timer-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    cursor: move;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    padding: 10px;
    border-radius: 5px;
    text-align: center;
  }
  
  .timer {
    background-color: #000;
    color: #0f0;
    font-family: 'Courier New', Courier, monospace;
    font-size: 24px;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  