/* Spinner.css */
.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .spinner {
    width: 70px;
    text-align: center;
  }
  
  .bounce1, .bounce2, .bounce3 {
    width: 10px;
    height: 10px;
    background-color: #00ffee;
    border-radius: 50%;
    display: inline-block;
    animation: bounce 1.4s infinite ease-in-out both;
  }
  
  .bounce1 {
    animation-delay: -0.32s;
  }
  
  .bounce2 {
    animation-delay: -0.16s;
  }
  
  @keyframes bounce {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  
  .countdown {
    margin-top: 20px;
    font-size: 1.5rem;
    color: #333;
  }
  
  .countdown span {
    font-weight: bold;
  }
  