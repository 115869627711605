.contact-list .contact-card {
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-list .link-warning {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  
  .contact-list .link-warning:hover {
    text-decoration: underline;
  }
  .join-community {
    padding: 10px;
    background-color: #f7f9fc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin: 20px 0;
    
  
  
    .community-link {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: #25D366; /* WhatsApp green color */
      font-weight: bold;
      font-size: 16px;
      transition: all 0.3s ease;
  
      &:hover {
        color: #888a15; /* Darker shade of WhatsApp green */
        text-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      }
  .coin-icon{
    font-size: 34px;
    margin-right: 8px;
    transition: transform 0.3s ease;
    color: #f57c00;

    &:hover {
      transform: scale(1.1);
    }
  }
      .whatsapp-icon {
        font-size: 34px;
        margin-right: 8px;
        transition: transform 0.3s ease;
  
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .icon-animation {
    animation: bounce 1s infinite;
  }
  

 

  @keyframes glow {
    0% {
      transform: scale(1);
      opacity: 0.7;
    }
    50% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
      transform: scale(1);
      opacity: 0.7;
    }
  }

  /* Window expand on hover */
  .arrow-container:hover + .window-effect {
    transform: scaleY(1); /* Expand the window */
  }
  .heading {
    font-size: 1.8rem;
    color: #4caf50;
  }
  
  .earn-tube {
    color: #f57c00;
    font-weight: bold;
  }
  
  .section-heading {
    font-size: 1.5rem;
    color: #3f51b5;
    margin-top: 20px;
  }
  
  .method-heading {
    font-size: 1.2rem;
    color: #ff5722;
    margin: 10px 0;
  }
  
  .method-description {
    font-size: 1rem;
    color: #616161;
    margin-bottom: 15px;
  }
  
  .highlight {
    color: #d32f2f;
    font-weight: bold;
  }
  
  .closing-text {
    margin-top: 20px;
    font-style: italic;
  }
  
  