/* UpdatePackage.css */
.dashboard-container {
    display: flex;
    align-items: center;
    padding: 5rem;
    background-color: #000000;
    color: white;
  }
  
  .right-content {
    margin-left: 20px;
    
  }
  
  .container-fluid {
    padding: 20px;
  }
  
  .dashboard {
    background-color: #000000;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .form-label {
    font-size: 1.2rem;
  }
  
  .form-control {
    font-size: 1rem;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .form-control:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .form-check-label {
    font-size: 1rem;
  }
  
  .btn-primary {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .btn-danger {
    background-color: #dc3545;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .btn-danger:hover {
    background-color: #c82333;
  }
  
  .mb-3 {
    margin-bottom: 20px;
  }
  
  .m-3 {
    margin: 20px;
  }
  
  .p-3 {
    padding: 20px;
  }
  
  .w-75 {
    width: 75%;
  }
  