
  .form_container {
    max-width: 450px;
    margin: auto;
    margin-top: 90px;
    padding: 2rem;
    background-color: rgb(31, 27, 27);
    border-radius: 8rem;
    box-shadow: 0 0 25px rgba(248, 246, 246, 0.938);
  }
  
  /* Form styles */
  form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  /* Heading styles */
  h2 {
    font-size: 2.4rem;
    text-align: center;
    color: var(--text-color);
    margin-bottom: 2rem;
  }
  
  /* Input styles */
  input {
    width: 85%;
    padding: 1.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1.6rem;
    background: transparent;
    color: var(--text-color);
    box-shadow: 0 0 25px rgb(63, 235, 220);
  }
  
  input:focus {
    border-color: var(--main-color);
    outline: none;
    background-color: black; /* Background color on hover */
    color: white;
  }
  
  /* Button styles */
  button {
    width: 10rem;
    padding: 1rem 2rem;
    background: var(--main-color);
    color: var(--bg-color);
    border-radius: 5px;
    font-size: 1.6rem;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
  }
  
  button:hover {
    background: black; /* Change background color to black on hover */
    color: white; /* Change text color to white on hover */
  }
  
  /* Margin-bottom utility classes */
  .mb-3 {
    margin-bottom: 3rem;
  }
  
  .mb-2 {
    margin-bottom: 0rem;
    color: rgb(226, 195, 18);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    html {
      font-size: 55%;
    }
  
    .form_container {
      padding: 2rem;
    }
  
    h2 {
      font-size: 2rem;
    }
  
    input {
      font-size: 1.4rem;
    }
  
    button {
      font-size: 1.4rem;
    }
  }
  
  @media (max-width: 375px) {
    .form_container {
      width: 90%;
      height: 90%;
    }
  }
  
  .btm {
    background-color: #c9a504;
  }
  