.services {
    padding: 5rem 12%;
    background: var(--bg-color);
    overflow: hidden;
}
.heading{
    padding: 3rem;
    margin-bottom: 3rem;
    margin-top: 3rem;
    font-size: 5rem;
    text-align: center;
    margin-bottom: 5rem;
    color: var(--text-color);
}


.services-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
}

.services-box {
    background: var(--second-bg-color);
    border-radius: 1.5rem;
    box-shadow: 0 0 25px rgba(236, 235, 235, 0.2);
    padding: 2.5rem;
    flex: 1 1 300px; /* Flex grow/shrink with a base width of 300px */
    max-width: 400px; /* Ensures the box doesn't grow too wide */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.services-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 50px var(--main-color);
}

.service-info h4 {
    font-size: 2.4rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: var(--main-color);
}

.service-info p {
    font-size: 1.6rem;
    line-height: 1.8;
    color: var(--text-color);
}


