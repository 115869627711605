/* Center the container */
.auto-earn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: #000000; /* Light grey background */
}



.circle-background {
  width: 500px;
  height: 500px;
  background-image: url('../../public/lgo192.png'); /* Add your background image */
  background-size: cover;
  background-position: center;
  border-radius: 50%; /* Makes it a circle */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  position: relative; /* To position the overlay */
}

/* Overlay to lighten the image */
.circle-background::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5); /* Light overlay */
  border-radius: 50%; /* Makes it a circle */
  z-index: 1; /* Place it above the image */
}

/* Button styling */
.start-button {
  background-color: #ff6347;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 15px 30px;
  font-size: 18px;
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  position: relative; /* Keeps the button above the overlay */
  z-index: 2; /* Button above overlay */
}
.auto-earn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: #000000; /* Light grey background */
  position: relative; /* For positioning of footer */
}


/* Earnings Container */
/* Earnings Container */
.earnings-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px; /* Optional: set a max width */
  margin-bottom: 20px; /* Space between earnings boxes and circle */
  position: absolute; /* Position it above the circle */
  top: 20px; /* Adjust as needed */
  z-index: 10; /* Ensure it's above the circle */
}

/* Earnings Box */
.earnings-box {
  background-color: white; /* White background for boxes */

  border: 1px solid #ddd; /* Light border */
  border-radius: 8px; /* Rounded corners */
  padding: 15px; /* Padding */
  text-align: center; /* Centered text */
  flex: 1; /* Equal space for both boxes */
  margin: 0 10px; /* Margin between boxes */
}
.start-button:disabled {
  background-color: rgb(58, 46, 46);
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.6;
}
.footer-box:disabled{
  background-color: rgb(58, 46, 46);
  cursor: not-allowed;
  pointer-events: none;
  font-size: 20px;
  opacity: 0.6;
}
/* Footer Box */
.footer-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff; /* White background */
  border: 1px solid #ddd; /* Light border */
  border-radius: 8px; /* Rounded corners */
  padding: 10px 20px; /* Padding */
  position: absolute; /* Position at the bottom */
  bottom: 20px; /* Distance from bottom */
  width: calc(70% - 40px); /* Full width minus padding */
  
}

/* Boost Icon */
.boost-icon {
  margin-right: 10px; /* Space between icon and text */
  color: #ff6347; /* Icon color */
}
.claim-button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
}



.message-container {
  background-color: #f0f8ff; /* Light blue background */
  padding: 10px;
  border-radius: 5px;
  margin: 20px 0;
  font-weight: bold;
  color: #333; /* Dark text */
}


.earnings-info-container {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin-top: 30px;
  padding: 20px;
 
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.earnings-circle {
  background: linear-gradient(145deg, #d4af37, #b8871e);
  border-radius: 50%;
  padding: 20px;
  width: 120px;
  height: 120px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.earnings-circle:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.earnings-circle h4 {
  margin: 0;
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.earnings-circle p {
  font-size: 22px;
  font-weight: bold;
  color: #f0e68c;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  margin: 8px 0 0;
}
.earnings-info-container {
  display: flex;
  gap: 20px; /* Space between each circle */
  align-items: center;
}

.earnings-circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  background-color: #f1f1f1; /* Light background color */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.earnings-circle h4,
.earnings-circle p {
  font-size: 16px;
  margin: 0;
  color: #333;
  white-space: normal;
}
