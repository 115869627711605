/* Add custom styles here */

.card {
    margin-top: 20px;
    padding: 20px;
  }
  
  .card-title {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .card-text {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  h2 {
    margin-top: 20px;
    font-size: 1.5rem;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  