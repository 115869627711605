/* PageNotFound.css */

.pnf-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;
    color: #343a40;
  }
  
  .pnf-title {
    font-size: 10rem;
    font-weight: bold;
    margin: 0;
    color: #dc3545; /* Red color for emphasis */
  }
  
  .pnf-heading {
    font-size: 2.5rem;
    color: black;
    margin: 20px 0;
  }
  
  .pnf-btn {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.2rem;
    color: #fff;
    background-color: #007bff; /* Blue button */
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .pnf-btn:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  