/* General Container */
.user-level-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    text-align: center;
  }
  
  .title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  /* Levels Grid */
  .levels-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  /* Card Design */
  .level-card {
    position: relative; /* To position overlay */
    background: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden; /* To ensure overlay stays within the card */
  }
  
  .level-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  }
  
  .level-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #007bff;
  }
  
  .level-card p {
    margin: 8px 0;
    color: #555;
  }
  
  .level-card strong {
    color: #000;
  }
  
  /* Overlay for Locked Levels */
  .card-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent overlay */
    display: flex;
    flex-direction: column; /* Stack lock text and button */
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    z-index: 2; /* Ensures overlay is above the card content */
    border-radius: 8px;
    transition: opacity 0.3s ease;
  }
  
  /* Unlock Button in Overlay */
  .overlay-unlock-button {
    margin-top: 10px;
    padding: 10px 15px;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .overlay-unlock-button:hover {
    background-color:rgba(0, 0, 0, 0.6);
  }
  
  @media (max-width: 768px) {
    .levels-grid {
      grid-template-columns: 1fr; /* Make cards stack vertically */
    }
    .level-card {
      font-size: 14px; /* Reduce font size */
      padding: 10px;   /* Adjust padding */
    }
  }
  


  