/* AdminDashboard.css */

.dashboard-container {
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;
}

.dashboard-container img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.right-content {
  text-align: center;
}

.right-content h1 {
  font-weight: bold;
  color: #333;
}

.right-content p {
  font-size: 18px;
  color: #666;
}

.right-content span {
  font-size: 24px;
  margin-right: 10px;
  color: #e0c10c;
  font-weight: bold;
}

.container-fluid {
  margin-top: 20px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.col-md-3 {
  width: 25%;
  padding: 20px;
}

.col-md-9 {
  width: 75%;
  padding: 20px;
}

.card {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card.bg-dark {
  background-color: #be1f1f;
  color: #fff;
}

.card.mb-3 {
  margin-bottom: 20px;
}

.card.p-5 {
  padding: 40px;
}

.card.w-75 {
  width: 75%;
}

.card.h-100 {
  height: 100%;
}

.user-count-box {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.count-card {
  width: 100%;
  padding: 20px;
  background-color: #333;
  color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.count-card h4 {
  font-size: 24px;
  font-weight: bold;
  color: yellow;
}