/* BuyPackage.css */
.dashboard-container {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #333;
    color: white;
  }
  
  .right-content {
    margin-left: 20px;
  }
  
  .container {
    padding: 20px;
  }
  
  .card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
   
  }
  
  .card-title {
    font-size: 2.5rem;
    font-weight: bold;
    background-color: #333;
    color: white;
    border-radius: 5px;
  }
  
  .card-text {
    font-size: 1.5rem;
    color: #333;
  }
  
  
  

  
  .btn:disabled {
    background-color: #a39696;
    cursor: not-allowed;
  }
  
  .inactive-package {
    box-shadow: 0 4px 8px rgba(255, 0, 0, 0.5);
  }
  
  .coming-soon-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(14, 1, 1, 0.897);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.5rem;
    border-radius: 10px;
    opacity: 0.9;
    z-index: 1;
  }
  
  .text-center {
    text-align: center;
  }
  
  .text-light {
    color: white;
  }
  
  .bg-dark {
    background-color: #333;
  }
  
  .py-4 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  
  .mb-5 {
    margin-bottom: 3rem;
  }
  
  .mb-4 {
    margin-bottom: 1.5rem;
  }
  
  .p-9 {
    padding: 3rem;
  }
  
  .p-4 {
    padding: 1.5rem;
  }
  