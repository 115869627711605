@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
}

:root {
    --bg-color: #fdfdfd;
    --text-color: #333;
    --main-color: #754ef9;
    --white-color: #fdfdfd;
    --shadow-color: rgba(0, 0, 0, .2);
}

*::selection {
    background: var(--main-color);
    color: var(--bg-color);
}

.dark-mode {
    --bg-color: #0b061f;
    --text-color: #fdfdfd;
    --shadow-color: rgba(0, 0, 0, .7);
}

html {
    font-size: 62.5%;
    overflow-x: hidden;
}

body {
    background: var(--bg-color);
    color: var(--text-color);
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 2rem 7%;
    background: transparent;
    display: flex;
    align-items: center;
    z-index: 100;
    transition: .5s;
}

.header.sticky {
    background: var(--bg-color);
    box-shadow: 0 .1rem 1rem var(--shadow-color);
}

.logo {
    font-size: 2.5rem;
    color: var(--main-color);
    font-weight: 600;
    margin-right: auto;
}

.navbar a {
    position: relative;
    font-size: 1.7rem;
    color: var(--white-color);
    font-weight: 500;
    margin-right: 3.5rem;
}

.header.sticky .navbar a {
    color: var(--text-color);
}

.header.sticky .navbar a.active {
    color: var(--main-color);
}

.navbar a.active::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -6px;
    width: 100%;
    height: .2rem;
    background: var(--white-color);
}

.header.sticky .navbar a::before {
    background: var(--main-color);
    opacity: .7;
}

#darkMode-icon {
    font-size: 2.4rem;
    color: var(--white-color);
    cursor: pointer;
}

.header.sticky #darkMode-icon {
    color: var(--text-color);
    opacity: .9;
}

#menu-icon {
    font-size: 3.6rem;
    color: var(--text-color);
    display: none;
}

section {
    min-height: 100vh;
    padding: 10rem 7% 2rem;
}

.home {
    display: flex;
    align-items: center;
}

.home .home-content {
    max-width: 44rem;
}

.home-content h3 {
    font-size: 3.2rem;
    font-weight: 700;
    line-height: .3;
}

.home-content h1 {
    font-size: 5.6rem;
    font-weight: 700;
    margin-bottom: .3rem;
}

.home-content p {
    font-size: 1.6rem;
}

.home-content .social-media a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    background: transparent;
    border: .2rem solid var(--main-color);
    border-radius: 50%;
    box-shadow: 0 .2rem .5rem var(--shadow-color);
    font-size: 2rem;
    color: var(--main-color);
    margin: 2.5rem 1.5rem 3rem 0;
    transition: .5s ease;
}

.home-content .social-media a:hover {
    background: var(--main-color);
    color: var(--white-color);
}

.btn {
    display: inline-block;
    padding: 1.2rem 2.8rem;
    background: var(--main-color);
    border-radius: .6rem;
    box-shadow: 0 .2rem .5rem var(--shadow-color);
    font-size: 1.6rem;
    color: var(--white-color);
    letter-spacing: .1rem;
    font-weight: 600;
    border: .2rem solid transparent;
    transition: .5s ease;
}

.btn:hover {
    background: transparent;
    color: var(--main-color);
    border-color: var(--main-color);
}

.home .profession-container {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
}

.home .profession-container .profession-box {
    position: absolute;
    top: 0;
    right: 0;
    width: 768px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: professionRotate 13s ease-out infinite;
}

@keyframes professionRotate {

    0%,
    20% {
        transform: rotate(0deg);
    }

    25%,
    45% {
        transform: rotate(-90deg);
    }

    50%,
    70% {
        transform: rotate(-180deg);
    }

    75%,
    95% {
        transform: rotate(-270deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}

.home .profession-box .profession {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--main-color);
    transform: rotate(calc(360deg / 4 * var(--i)));
    transform-origin: 384px;
    background: var(--bg-color);
    padding: 13px 0;
}

.home .profession-box .profession:nth-child(1) i {
    margin-right: 15px;
}

.home .profession-box .profession:nth-child(2),
.home .profession-box .profession:nth-child(4) {
    padding-bottom: 20px;
}

.home .profession i {
    font-size: 3.8rem;
}

.home .profession h3 {
    font-size: 3.2rem;
    line-height: 1;
    font-weight: 600;
}

.home .profession-box .circle {
    width: 560px;
    height: 560px;
    border: 3px solid var(--main-color);
    border-radius: 50%;
    z-index: -1;
}

.home .profession-container .overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 50vh solid var(--main-color);
    border-right: 384px solid var(--main-color);
    border-bottom: 50vh solid var(--main-color);
    border-left: 384px solid transparent;
}

.home-img img {
    position: absolute;
    bottom: 0;
    right: 40px;
    pointer-events: none;
    max-width: 480px;
    max-height: 100vh;
}

span {
    color: var(--main-color);
}

.about {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
}

.about-img img {
    width: 40vw;
}

.heading {
    font-size: 4.5rem;
    text-align: center;
}

.about-content h2 {
    text-align: left;
    line-height: 1.2;
}

.about-content h3 {
    font-size: 2rem;
}

.about-content p {
    font-size: 1.6rem;
    margin: 2rem 0 3rem;
}

.services {
    min-height: auto;
    padding-bottom: 10rem;
}

.services h2 {
    margin-bottom: 5rem;
}

.services .services-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
}

.services-container .services-box {
    flex: 1 1 30rem;
    background: var(--bg-color);
    padding: 3rem 2rem 4rem;
    border-radius: 2rem;
    box-shadow: 0 .1rem .5rem var(--shadow-color);
    text-align: center;
    border-top: .6rem solid var(--main-color);
    border-bottom: .6rem solid var(--main-color);
    transition: .5s ease;
}

.services-container .services-box:hover {
    box-shadow: 0 .1rem 2rem var(--shadow-color);
    transform: scale(1.02);
}

.services-box i {
    font-size: 7rem;
    color: var(--main-color);
}

.services-box h3 {
    font-size: 2.6rem;
    transition: .5s ease;
}

.services-box:hover h3 {
    color: var(--main-color);
}

.services-box p {
    font-size: 1.6rem;
    margin: 1rem 0 3rem;
}

.portfolio {
    min-height: auto;
    padding-bottom: 10rem;
}

.portfolio h2 {
    margin-bottom: 4rem;
}

.portfolio .portfolio-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    gap: 2.5rem;
}

.portfolio-container .portfolio-box {
    position: relative;
    display: flex;
    border-radius: 2rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, .1);
    overflow: hidden;
}

.portfolio-box img {
    width: 100%;
    transition: .5s ease;
}

.portfolio-box:hover img {
    transform: scale(1.1);
}

.portfolio-box .portfolio-layer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, .1), var(--main-color));
    color: var(--white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 0 4rem;
    opacity: 0;
    transition: .5s ease;
}

.portfolio-box:hover .portfolio-layer {
    opacity: 1;
}

.portfolio-layer h4 {
    font-size: 3rem;
}

.portfolio-layer p {
    font-size: 1.6rem;
    margin: .3rem 0 1rem;
}

.portfolio-layer a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    background: var(--white-color);
    border-radius: 50%;
}

.portfolio-layer a i {
    font-size: 2rem;
    color: #333;
}

.testimonial-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 5rem 1rem;
}

.testimonial-container .testimonial-wrapper {
    position: relative;
    max-width: 90rem;
    width: 100%;
    padding: 5rem;
}

.testimonial-wrapper .testimonial-box {
    padding: 1rem;
    border-radius: 2rem;
    overflow: hidden;
}

.testimonial-content .testimonial-slide {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: var(--bg-color);
    border-radius: 2rem;
    box-shadow: 0 .1rem .5rem var(--shadow-color);
    padding: 3rem 5rem;
    border-top: .8rem solid var(--main-color);
    border-bottom: .8rem solid var(--main-color);
}

.testimonial-slide img {
    width: 14rem;
    height: 14rem;
    object-fit: cover;
    border-radius: 50%;
    border: .5rem solid var(--bg-color);
    outline: .5rem solid var(--main-color);
}

.testimonial-slide h3 {
    font-size: 2.5rem;
    margin: 2rem 0;
}

.testimonial-slide p {
    font-size: 1.4rem;
    text-align: center;
}

.testimonial-box .swiper-button-next,
.testimonial-box .swiper-button-prev {
    color: var(--main-color);
}

.testimonial-box .swiper-button-next {
    right: 0;
}

.testimonial-box .swiper-button-prev {
    left: 0;
}

.testimonial-box .swiper-pagination-bullet {
    background: rgba(0, 0, 0, .8);
}

.testimonial-box .swiper-pagination-bullet-active {
    background: var(--main-color);
}

.contact {
    min-height: auto;
    padding-bottom: 7rem;
}

.contact h2 {
    margin-bottom: 3rem;
}

.contact form {
    max-width: 70rem;
    margin: 1rem auto;
    text-align: center;
    margin-bottom: 3rem;
}

.contact form .input-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.contact form .input-box input,
.contact form textarea {
    width: 100%;
    padding: 1.5rem;
    font-size: 1.6rem;
    color: var(--text-color);
    background: var(--bg-color);
    border-radius: .8rem;
    margin: .7rem 0;
    box-shadow: 0 .1rem .5rem var(--shadow-color);
}

.contact form .input-box input {
    width: 49%;
}

.contact form textarea {
    resize: none;
}

.contact form .btn {
    margin-top: 2rem;
    cursor: pointer;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 2rem 7%;
    background: var(--main-color);
}

.footer-text p {
    font-size: 1.6rem;
    color: var(--white-color);
}

.footer-iconTop a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: .8rem;
    background: var(--white-color);
    border-radius: .8rem;
    border: .2rem solid var(--main-color);
    outline: .2rem solid transparent;
    transition: .5s ease;
}

.footer-iconTop a:hover {
    outline-color: var(--white-color);
}

.footer-iconTop a i {
    font-size: 2.4rem;
    color: #333;
}


/* BREAKPOINTS */
@media (max-width: 1200px) {
    html {
        font-size: 55%;
    }

    .home .profession-container .profession-box {
        right: -10%;
    }

    .home .profession-container .overlay {
        right: -6%;
    }

    .home-img img {
        right: 0;
    }
}

@media (max-width: 1100px) {
    .home .profession-container .profession-box {
        right: -15%;
    }

    .home-img img {
        max-width: 420px;
    }
}

@media (max-width: 1024px) {
    .header {
        padding: 2rem 3%;
    }

    section {
        padding: 10rem 3% 2rem;
    }

    .home .profession-container .profession-box {
        right: -20%;
    }

    .home .profession-container .overlay {
        right: -12%;
    }

    .home-img img {
        max-width: 400px;
    }
}

@media (max-width: 991px) {

    .navbar a:nth-child(1) {
        color: var(--main-color);
    }

    .navbar a.active::before {
        background: var(--main-color);
        opacity: .7;
    }

    .home .home-content {
        max-width: 50rem;
    }

    .home .profession-container .profession-box {
        right: -35%;
    }

    .home .profession-container .overlay {
        right: -30%;
    }

    .home-img img {
        display: none;
    }

    .footer {
        padding: 2rem 3%;
    }
}

@media (max-width: 896px) {
    .navbar a:nth-child(2) {
        color: var(--main-color);
    }

    .home .profession-container .profession-box {
        right: -50%;
    }

    .home .profession-container .overlay {
        right: -43%;
    }
}

@media (max-width: 879px) {
    .portfolio .portfolio-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 780px) {
    .navbar a:nth-child(2) {
        color: var(--white-color);
    }
}

@media (max-width: 768px) {
    #menu-icon {
        display: block;
    }

    #darkMode-icon {
        position: absolute;
        right: 7rem;
        font-size: 2.6rem;
        color: var(--text-color);
        margin-bottom: .1rem;
    }

    .navbar {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 1rem 3%;
        background: var(--bg-color);
        border-top: .1rem solid rgba(0, 0, 0, .2);
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
        display: none;
    }

    .navbar.active {
        display: block;
    }

    .navbar a {
        display: block;
        font-size: 2rem;
        margin: 3rem 0;
        color: var(--text-color);
    }

    .navbar a:nth-child(1),
    .navbar a:nth-child(2) {
        color: var(--text-color);
    }

    .navbar a.active {
        color: var(--main-color);
    }

    .navbar a::before {
        display: none;
    }

    .home {
        padding: 0 3% 23rem;
        justify-content: center;
        text-align: center;
    }

    .home-content h3 {
        font-size: 2.6rem;
    }

    .home-content h1 {
        font-size: 5rem;
    }

    .home-content .social-media a {
        margin: 2.5rem .75rem 3rem;
    }

    .home .profession-container {
        left: 0;
        width: 100%;
        height: 100%;
    }

    .home .profession-container .profession-box {
        position: fixed;
        top: 450px;
        left: 0;
        width: 100%;
    }

    .home .profession-box .profession {
        padding: 0 13px;
        left: auto;
        transform-origin: 0;
    }

    .home .profession-box .profession:nth-child(1) {
        transform: rotate(-90deg) translate(-120px, -210px);
    }

    .home .profession-box .profession:nth-child(1) i {
        margin-right: 0;
    }

    .home .profession-box .profession:nth-child(2) {
        transform: rotate(0deg) translate(0, -325px);
    }

    .home .profession-box .profession:nth-child(3) {
        transform: rotate(90deg) translate(-115px, -450px);
    }

    .home .profession-box .profession:nth-child(4) {
        transform: rotate(180deg) translate(-220px, -325px);
    }

    .home .profession-box .circle {
        position: fixed;
        width: 670px;
        height: 670px;
        z-index: -1;
    }

    .home .profession-container .overlay {
        position: fixed;
        top: 70rem;
        left: 50%;
        right: 0;
        transform: rotate(90deg) translate(-50%, 50%) scaleY(3);
        border-width: 23.9rem;
    }

    .about {
        flex-direction: column-reverse;
        text-align: center;
    }

    .about-content h2 {
        text-align: center;
    }

    .about-img img {
        width: 70vw;
        margin-top: -2rem;
    }

    .testimonial-container .testimonial-wrapper {
        padding: 5rem 0;
    }

    .testimonial-content .testimonial-slide {
        padding: 3rem 2rem;
    }

    .testimonial-box .swiper-button-next,
    .testimonial-box .swiper-button-prev {
        display: none;
    }
}

@media (max-width: 580px) {
    .portfolio .portfolio-container {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 450px) {
    html {
        font-size: 50%;
    }

    #darkMode-icon {
        right: 6rem;
    }

    .home .profession-box .profession:nth-child(1) {
        transform: rotate(-90deg) translate(-110px, -220px);
    }

    .home .profession-box .profession:nth-child(2) {
        transform: rotate(0deg) translate(5px, -325px);
    }

    .home .profession-box .profession:nth-child(3) {
        transform: rotate(90deg) translate(-105px, -440px);
    }

    .home .profession-box .profession:nth-child(4) {
        transform: rotate(180deg) translate(-210px, -325px);
    }

    .home .profession-container .overlay {
        top: 80rem;
    }

    .contact form .input-box input {
        width: 100%;
    }

    .footer {
        flex-direction: column-reverse;
    }

    .footer p {
        text-align: center;
        margin-top: 2rem;
    }
}


@media (max-width: 365px) {
    .about-img img {
        width: 90vw;
    }
}

@media (max-width: 315px) {
    .home-content h1 {
        font-size: 4.5rem;
    }
}
img {
    border-radius: 20px;
}

.about {
    padding: 50px 20px;
    background: #f9f9f9;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .heading {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .heading span {
    color: #ff6347;
  }
  
  .about-section {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
  }
  
  .images {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .images img {
    width: 48%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
  }
  
  .about-content {
    flex: 1;
    max-width: 600px;
    color: #555;
  }
  
  .about-content h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #333;
  }
  
  .about-content p {
    font-size: 1rem;
    margin-bottom: 10px;
    line-height: 1.6;
  }
  
  .btn {
    display: inline-block;
    padding: 10px 20px;
    background: #ff6347;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1rem;
    transition: background 0.3s;
  }
  
  .btn:hover {
    background: #e5533d;
  }
  .about {
    padding: 50px 20px;
    background: linear-gradient(to bottom, #a59336, #f4f4f4);
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .heading {
    text-align: center;
    font-size: 4rem;
    margin-bottom: 30px;
    color: #333;
  }
  
  .heading span {
    color: #ff6347;
  }
  
  .about-section {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 30px;
  }
  
  .images {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .images img {
    width: 48%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-content {
    flex: 1;
    max-width: 600px;
    color: #555;
    line-height: 1.8;
  }
  
  .about-content h3 {
    font-size: 3rem;
    margin-bottom: 15px;
    color: #333;
  }
  
  .about-content h4 {
    font-size: 2rem;
    margin-top: 20px;
    color: #444;
  }
  
  .about-content p {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .about-content ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }
  
  .about-content ul li {
    margin-bottom: 10px;
    font-size: 1rem;
    position: relative;
    padding-left: 20px;
  }
  
  .about-content ul li::before {
    content: "✔";
    color: #ff6347;
    position: absolute;
    left: 0;
    top: 0;
  }
  
  .about-content ol {
    padding-left: 20px;
    margin-top: 10px;
  }
  
  .about-content ol li {
    margin-bottom: 10px;
    font-size: 1rem;
  }
  
  .btn {
    display: inline-block;
    padding: 12px 30px;
    background: #ff6347;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1.2rem;
    transition: transform 0.3s, background 0.3s;
  }
  
  .btn:hover {
    background: #e5533d;
    transform: translateY(-3px);
  }
  

  /*
  earn*/
  .services {
    padding: 50px 20px;
    background: #f4f4f4;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .heading {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 30px;
    color: #333;
  }
  
  .heading span {
    color: #ff6347;
  }
  
  .services-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .service-card {
    position: relative;
    width: 300px;
    height: 200px;
    padding: 20px;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .service-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #ff6347;
  }
  
  .service-card p {
    font-size: 1rem;
    color: #ffffff;
  }
  
  .hover-details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 10px;
  }
  
  .service-card:hover .hover-details {
    opacity: 1;
  }
  
  .services-details {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 50px;
  }
  
  .content {
    flex: 1;
    max-width: 600px;
  }
  
  .content h3 {
    font-size: 3rem;
    color: #e75454;
    margin-bottom: 20px;
  }
  
  .content p {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #ffffff;
    line-height: 1.8;
  }
  
  .content ul {
    list-style: disc;
    padding-left: 20px;
  }
  
  .content ul li {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #ffffff;
  }



  /*earn
  */
  .services-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .service-card {
    position: relative;
    width: 300px;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    text-align: center;
  }
  
  .service-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s;
  }
  
  .service-card h3 {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px 10px;
    font-size: 1.2rem;
    border-radius: 5px;
    transition: opacity 0.3s;
    opacity: 1;
  }
  
  .service-card:hover img {
    transform: scale(1.1);
  }
  
  .service-card:hover h3 {
    opacity: 0;
  }
  
  .hover-details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .hover-details h3 {
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: #ff6347;
  }
  
  .hover-details p {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .service-card:hover .hover-details {
    opacity: 1;
  }
  .testimonials {
    padding: 50px 20px;
    background: #f9f9f9;
    text-align: center;
    overflow: hidden;
  }
  
  .testimonials .heading {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .testimonials .heading span {
    color: #ff6347;
  }
  
  .testimonial-slider {
    display: flex;
    gap: 20px;
    width: 100%;
    overflow: hidden;
    position: relative;
    animation: slideTestimonials 15s infinite ease-in-out;
  }
  
  .testimonial-card {
    min-width: 300px;
    max-width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    flex-shrink: 0;
    opacity: 0.7;
    transition: opacity 0.5s ease;
  }
  
  .testimonial-card:hover {
    opacity: 1;
  }
  
  .testimonial-card:nth-child(1) {
    opacity: 1; /* First card fully visible */
  }
  
  .testimonial-card img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .testimonial-card .review-text {
    font-size: 1.5rem;
    color: #ffffff;
    margin-bottom: 15px;
  }
  
  .testimonial-card .user-info h3 {
    font-size: 2rem;
    color: #ffffff;
  }
  
  .stars {
    color: #ffd700;
    font-size: 3rem;
  }
  
  @keyframes slideTestimonials {
    0%, 25% {
      transform: translateX(0);
    }
    26%, 50% {
      transform: translateX(-100%);
    }
    51%, 75% {
      transform: translateX(-200%);
    }
    76%, 100% {
      transform: translateX(-300%);
    }
  }
  .testimonials {
    background-color: #000;
    color: #fff;
    padding: 40px 20px;
    text-align: center;
  }
  
  .heading {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .heading span {
    color: #f39c12;
  }
  
  .testimonial-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .testimonial-card {
    background: #1c1c1c;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
  }
  
  .testimonial-card:hover {
    transform: translateY(-10px);
  }
  
  .user-image {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 15px;
  }
  
  .review-text {
    font-size: 2rem;
    margin: 10px 0;
    color: #ddd;
  }
  
  .user-info h3 {
    font-size: 2rem;
    margin: 5px 0;
  }
  
  .stars {
    color: #f39c12;
  }
  
  .additional-content {
    margin-top: 30px;
    font-size: 2rem;
    color: #ddd;
    padding: 10px;
    border-top: 1px solid #333;
  }
  .blog-section {
    background-color: #000;
    color: #fff;
    padding: 40px 20px;
  }
  
  .heading {
    font-size: 2.5rem;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .heading span {
    color: #f39c12;
  }
  
  .blog-container {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    gap: 20px;
  }
  
  .blog-container.image-left {
    flex-direction: row;
  }
  
  .blog-container.image-right {
    flex-direction: row-reverse;
  }
  
  .blog-image {
    width: 20%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }
  
  .blog-content {
    width: 50%;
    padding: 20px;
  }
  
  .blog-content h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #f39c12;
  }
  
  .blog-content p {
    font-size: 1.5rem;
    line-height: 1.6;
    color: #ddd;
  }
  